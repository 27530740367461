<template>
  <div>
    <!-- 修改增加弹窗 -->
      <division-pop :popShow="popShow" @popshow="Popshow" :vai="vai" @name="nameValue"/>
    <el-row class="top">
        <el-col :span="19">部门管理</el-col>
        <el-col :span="5">操作</el-col>
    </el-row>
   <el-tree
      :data="data"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false">
      <el-row class="custom-tree-node" slot-scope="{  data }">
        <el-col :span="19">{{ data.name }}</el-col>
        <el-col :span="5" >
           <span class="compile" @click.stop="compile(true,1,data.id)">修改</span>
           <span class="addGrouping" @click.stop="compile(true,0,data.id)" >添加下级机构</span>
            <span v-if="(data.parent_id != 0)" class="delete" @click.stop="remove(data.id)" >删除</span>
        </el-col>
      </el-row>
    </el-tree>
  </div>
</template>

<script>
import DivisionPop from './DivisionPop.vue'
import { getDepartmentList, editDepartment, addDepartment, department } from '@/api/rbac'
import { filterArray } from '@/utils/recursion'
export default {
  components: { DivisionPop },
  data () {
    return {
   data:[],
      popShow:false,  //弹窗显示
      vai:0,   //变量
      parentId:null, //上级ID
    }
  },
  created () {
    this.getDepartmentList()
  },
  mounted () {

  },
  methods: {
      compile(val,i,id){
          this.popShow = val

          this.parentId = id
          this.vai = i
      },
      Popshow(val){
          this.popShow = val
      },
      // 修改增加部门
    async nameValue(val){
        if(this.vai === 0) {
          try{ 
            const { data, msg } = await addDepartment({name:val,parent_id:this.parentId})
            this.$message({
             message: msg,
             type: 'success'
            });
            this.getDepartmentList()
            this.parentId = null
          } catch(e){
             this.$message.error(e.msg);
          }
        } else{
          try{ 
            const { data, msg } = await editDepartment( {departmentId:this.parentId,name:val})
            this.$message({
             message: msg,
             type: 'success'
            });
            this.getDepartmentList()
            this.parentId = null
          } catch(e){
             this.$message.error(e.msg);
          }
        }
      },
      // 删除部门
      async remove(id){
        try{
           const { data, msg } = await department({departmentId: id})
           this.$message({
             message: msg,
             type: 'success'
            });
            this.getDepartmentList()
        } catch(e){
          this.$message.error(e.msg);
        }
      },
      // 获取部门列表
    async getDepartmentList(){
        try{
          let arr = []
          const { data, msg } = await getDepartmentList()
          arr.push(data.info)
          this.data = arr 
        } catch(e){
          console.log(e);
        }
      }
  }
}
</script>

<style scoped lang="less">
.top{
    padding: 15px 0 15px 20px;
    background: #F5F5F5;
}

.custom-tree-node{
    width: 100%;
    padding: 15px 0;
}
.compile {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333;
    line-height: 20px;
    margin-right: 10px;
    cursor:pointer;
}
.delete {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FA4A14;
    line-height: 20px;
    cursor:pointer;
    margin-left: 10px;
}
.addGrouping {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    cursor:pointer;
}
 /deep/.el-tree-node__content{
        height: 50px;
        border-bottom:1px solid #e9e9e9 ;
    }
</style>
