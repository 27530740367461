<template>
  <div>
    <division-tree />
  </div>
</template>

<script>
import DivisionTree from './components/DivisionTree.vue'
export default {
  components: { DivisionTree },
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">

</style>
