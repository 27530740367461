<template>
  <div>
<el-dialog
  :title="vai===1 ? '修改' : '新增部门'"
  :visible.sync="popShow"
  width="520px"
  :before-close="handleClose">
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <el-form-item label="部门名称" prop="name">
    <el-input v-model="ruleForm.name"></el-input>
  </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="sure">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  props:{
      popShow:{
          type:Boolean
      },
      vai:{
          type:Number
      },
  },
  data () {
    return {
        ruleForm:{
            name:''
        },
        rules:{
            name:[
                { required: true, message: '请输入部门名称', trigger: 'blur' }
            ]
        }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
      handleClose(){
          this.$emit('popshow',false)
      },
      sure(){
        if(this.ruleForm.name != ''){
        this.$emit('name',this.ruleForm.name)
        this.handleClose()
        this.ruleForm.name = ''
        } else{
           this.$message({
          message: '部门名称不能为空哦！',
          type: 'warning'
        });
        }
      }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog{
    // height: 571px;
    background: #FFFFFF;
    box-shadow: 0px 6px 16px 4px rgba(51, 51, 51, 0.3);
    border-radius: 10px;
    padding: 0 20px;
    .el-dialog__header{
        // margin: 0 20px;
        padding-left:0;
        padding-right: 0;
        border-bottom: 1px solid #d8d8d8;
    }
    .el-dialog__body{
        padding: 20px 10px;
        border-bottom: 1px solid #d8d8d8;
    }
}
.el-input{
 width: 345px;
}
</style>
