import request from "@/utils/request";

const prefix = '/rbac'
// 用户管理列表
export const userList = (
    callback
) => {
    return request(`${prefix}/getSubAccountList`, {params: {}, method: 'get'}, callback)
}
// 用户详细
export const subAccount = (
    {subAccountId},
    callback
) => {
    const data = {
        subAccountId
    }
    return request(`${prefix}/subAccount`, {params: data, method: 'get'}, callback)
}
// 新增用户
export const addSubAccount = (
    {
        departmentId,
        alias,
        password,
        email,
        phone,
        verifyCode,
        roleId,
        remark
    },
    callback
) => {
    const data = {
        departmentId,
        alias,
        password,
        email,
        phone,
        verifyCode,
        roleId,
        remark
    }
    return request(`${prefix}/addSubAccount`, {data, method: 'post'}, callback)
}
// 编辑用户
export const editSubAccount = (
    {
        subAccountId,
        departmentId,
        alias,
        password,
        email,
        phone,
        roleId,
        remark
    },
    callback
) => {
    const data = {
        subAccountId,
        departmentId,
        alias,
        password,
        email,
        phone,
        roleId,
        remark
    }
    return request(`${prefix}/editSubAccount`, {data, method: 'put'}, callback)
}
// 删除用户
export const DeletsubAccount = (
    {subAccountId},
    callback
) => {
    const data = {
        subAccountId
    }
    return request(`${prefix}/subAccount`, {params: data, method: 'delete'}, callback)
}
// 角色列表
export const getRoleList = (
    callback
) => {
    return request('/user/getRoleList', {params: {}, method: 'get'}, callback)
}
// 角色编辑
export const editRole = (
    {
        roleId,
        permissions,
        title
    },
    callback
) => {
    const data = {
        roleId,
        permissions,
        title
    }
    return request('/user/editRole', {data, method: 'put'}, callback)
}
// 角色新增
export const addRole = (
    {
        name,
        title,
        permissions
    },
    callback
) => {
    const data = {
        name, title, permissions
    }
    return request('/user/addRole', {data, method: 'post'}, callback)
}
// 删除角色
export const DeletRole = (
    {
        roleId
    },
    callback
) => {
    const data = {
        roleIds: roleId
    }
    return request('/user/deleteRole', {params: data, method: 'delete'}, callback)
}
//部门列表
export const getNormalDepartmentList = (
    callback
) => {
    return request(`${prefix}/getNormalDepartmentList`, {params: {}, method: 'get'}, callback)
}
// 部门列表-树状
export const getDepartmentList = (
    callback
) => {
    return request(`${prefix}/getDepartmentList`, {params: {}, method: 'get'}, callback)
}

export const departmentList = (
    callback
) => {
    return request('/user/index', {params: {}, method: 'get'}, callback)
}


// 部门编辑
export const editDepartment = (
    {
        departmentId,
        name
    },
    callback
) => {
    const data = {
        departmentId,
        name
    }
    return request(`${prefix}/editDepartment/`, {data, method: 'put'}, callback)
}
// 部门删除
export const department = (
    {departmentId},
    callback
) => {
    const data = {
        departmentId,
    }
    return request(`${prefix}/department`, {data: data, method: 'delete'}, callback)
}
// 部门新增
export const addDepartment = (
    {
        name,
        parent_id
    },
    callback
) => {
    const data = {
        name,
        parent_id
    }
    return request(`${prefix}/addDepartment`, {data, method: 'post'}, callback)
}
// 修改角色权限菜单
export const permissionList = (
    id,
    callback
) => {
    const data = {
        id
    }
    return request('/user/getPermissionList', {params: {}, method: 'get'}, callback)
}

//获取角色详情
export const getDetails = ({roleId}, callback) => {
    const params = {
        roleId
    }
    return request('/user/getRoleDetail', {params, method: 'get'}, callback)
}


// 创建商户管理员账户

export const createManageAccount = (
    {
        phone,
        password,
        passwordRepeat,
        roleId
    },
    callback
) => {
    const params = {
        phone,
        password,
        passwordRepeat,
        roleId
    }
    return request('/user/createManageAccount', {params, method: 'post'}, callback)
}

//禁用启用店铺
export const ableMerchantManager = (
    {
        merchantManagerId
    },
    callback
) => {
    const data = {
        merchantManagerId
    }
    return request('/user/ableMerchantManager', {data, method: 'put'}, callback)
}


export const detail = (
    {
        merchantManagerId
    },
    callback
) => {
    const params = {
        merchantManagerId
    }
    return request('/user/detail', {params, method: 'get'}, callback)
}

// 修改子账号信息

export const editInfo = (
    {
        roleId,
        password,
        passwordRepeat,
        merchantManagerId
    },
    callback
) => {
    const data = {
        roleId,
        password,
        passwordRepeat,
        merchantManagerId
    }
    return request('/user/editInfo', {data, method: 'put'}, callback)
}